import React from 'react';
import { FaGithub, FaLinkedin } from 'react-icons/fa';
import HeroBgAnimation from '../HeroBgAnimation';
import { HeroContainer, HeroBg, HeroLeftContainer, Img, HeroRightContainer, HeroInnerContainer, TextLoop, Title, Span, SubTitle, ResumeButton } from './HeroStyle';
import HeroImg from '../../images/new-Photoroom.png';
import Typewriter from 'typewriter-effect';
import { Bio } from '../../data/constants';
import { FaCalendarAlt } from 'react-icons/fa';

const HeroSection = () => {
    return (
        <div id="about">
            <HeroContainer>
                <HeroBg>
                    <HeroBgAnimation />
                </HeroBg>
                <HeroInnerContainer style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <HeroLeftContainer>
                        <Title>Hi, I am <br /> {Bio.name}</Title>
                        <TextLoop>
                            I am into
                            <Span>
                                <Typewriter
                                    options={{
                                        strings: Bio.roles,
                                        autoStart: true,
                                        loop: true,
                                    }}
                                />
                            </Span>
                        </TextLoop>
                        <SubTitle>{Bio.description}</SubTitle>
                        <div style={{ display: 'flex', marginLeft: '0px', marginTop: '13px' }}>
    <ResumeButton href={Bio.resume} target='_blank' style={{ height: '58px', width: '200px', display: 'block', alignItems: 'center', textAlign: 'center', fontSize: '1.1rem' }} title="Click to view my Resume">Check Resume</ResumeButton>
    <div style={{ marginLeft: '40px', marginRight: '0px', display: 'flex', alignItems: 'center',gap: '20px' }}>
        <a href={Bio.github} target="_blank" rel="noopener noreferrer" title="Visit My GitHub">
            <FaGithub size={30} style={{ color: '#9D00FF', cursor: 'pointer' }} />
        </a>
        <a href={Bio.linkedin} target="_blank" rel="noopener noreferrer" title="Visit My LinkedIn">
            <FaLinkedin size={30} style={{ color: '#9D00FF', cursor: 'pointer' }} />
        </a>
        <a href={Bio.calender} target="_blank" rel="noopener noreferrer" title="Book a meeting">
            <FaCalendarAlt size={30} style={{ color: '#9D00FF', cursor: 'pointer' }} />
        </a>
    </div>
</div>


                    </HeroLeftContainer>

                    <HeroRightContainer>
                        <Img src={HeroImg} alt="hero-image" />
                    </HeroRightContainer>
                </HeroInnerContainer>
            </HeroContainer>
        </div>
    );
};

export default HeroSection;
