import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa';

const ScrollButton = styled.button`
  position: fixed;
  right: 20px;
  z-index: 1000;
  background: linear-gradient(225deg, hsla(270, 100%, 50%, 1) 0%, hsla(330, 100%, 50%, 1) 50%, hsla(250, 100%, 50%, 1) 100%);
  border: none;
  color: white;
  cursor: pointer;
  font-size: 18px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
opacity: 0.7;
  &:hover {
    transform: scale(1.1);
    opacity: 1.5;
  }

  &:focus {
    outline: none;
  }
`;

const ScrollToTopButton = styled(ScrollButton)`
  bottom: 20px;
`;

const ScrollToBottomButton = styled(ScrollButton)`
  bottom: 20px;
`;

const ScrollNavigation = () => {
  const [showUpButton, setShowUpButton] = useState(false);
  const [showDownButton, setShowDownButton] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const windowHeight = window.innerHeight;
      const fullHeight = document.body.scrollHeight;

      if (scrollY === 0) {
        // At the very top
        setShowUpButton(false);
        setShowDownButton(true);
      } else if (scrollY + windowHeight >= fullHeight - 10) {
        // At the very bottom
        setShowUpButton(true);
        setShowDownButton(false);
      } else {
        // In between
        setShowUpButton(true);
        setShowDownButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const scrollToBottom = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  };

  return (
    <>
      {showUpButton && (
        <ScrollToTopButton onClick={scrollToTop} title="Scroll to Top">
          <FaArrowUp />
        </ScrollToTopButton>
      )}
      {showDownButton && (
        <ScrollToBottomButton onClick={scrollToBottom} title="Scroll to Bottom">
          <FaArrowDown />
        </ScrollToBottomButton>
      )}
    </>
  );
};

export default ScrollNavigation;
